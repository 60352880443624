import React from 'react';
import PageHeader from 'gatsby-theme-carbon/src/components/PageHeader';
import { useMetadata } from 'gatsby-theme-carbon/src/util/hooks';

const Custom404 = () => {
  const { interiorTheme } = useMetadata();
  return (
    <>
      <PageHeader title="404" theme={interiorTheme} />
      <p>Page not found</p>
    </>
  );
};

export default Custom404;
